import React, { Component } from 'react';
import ReactModal from "react-modal";
import Request from "../../../core/httpClient";
const request = new Request();

class ModalUserEddit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModalUserEddit: false,
            id: this.props.id,
            nombre: '',
            usuario: '',
            password: '',
            idUsuario: ''

        }
    }


    render() {
        return (
            <div>
                < div className="inquilino-btns btn-container " >
                    <button type="button" onClick={this.handleEddit.bind(this)} >
                        <i className="fas fa-pen" /> Editar
                                 </button>

                    <button type="button" onClick={this.handleDelete.bind(this)}  >
                        <i className="fas fa-times" /> Remover
                </button>

                </div>
                {
                    <ReactModal isOpen={this.state.showModalUserEddit} className="modal modal-houses column" overlayClassName="overlay" >
                        <form className="flex justify-center" onSubmit={this.handledSubmit.bind(this)}>
                            <div className="container column">

                                <div className="close-modal justify-end">
                                    <button className="btn-modal-close color-dark" onClick={this.handleEddit.bind(this)} >
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                                <div>
                                    <h3>Editar Usuario</h3>

                                </div>
                                <div className="white-space-16" />
                                <div className="column">
                                    <div className="align-center">
                                        <p><b>Nombre:</b></p>

                                        <input type="text" name="name" value={this.state.nombre} onChange={event => this.setState({ nombre: event.target.value })} className="input" placeholder="Nombre"
                                            required minLength="6" maxLength="100" />

                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Usuario:</b>
                                        </p>
                                        <input type="text" name="usuario" value={this.state.usuario} onChange={event => this.setState({ usuario: event.target.value })} className="input" placeholder="Usuario"
                                            required minLength="6" maxLength="150" />
                                    </div>

                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Password:</b>
                                        </p>
                                        <input type="password" name="password" value={this.state.password} onChange={event => this.setState({ password: event.target.value })} className="input" placeholder="Password"
                                            required minLength="6" maxLength="150" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Perfil:</b>
                                        </p>
                                        <select name="perfil" id="" className="input" disabled>
                                            <option value="2">administrador</option>
                                        </select>
                                    </div>

                                </div>
                                <div className="white-space-32" />
                                <div className="btn-container">
                                    <button type="submit" className="btn btn-primary color-white">
                                        <i className="fas fa-sync-alt"></i>
                                        &nbsp; ACTUALIZAR
                                </button>
                                    <button type="button" className="btn btn-secondary color-white" onClick={this.handleEddit.bind(this)}>
                                        <i className="fas fa-times" />
                                        &nbsp; CANCELAR
                                   </button>
                                </div>
                            </div>
                        </form>
                    </ReactModal>
                }
            </div>

        );
    }
    handleClose() {
        this.setState({ showModalUserEddit: false, disable: true })
    }
    handleEddit() {
        this.setState({ showModalUserEddit: !this.state.showModalUserEddit })
        this.getUser()
    }
    handleDelete() {


        this.getUser()
        let data = {
            IdUsuario: this.state.id
        };

        this.props.deleteUser(data)

    }
    // traerUsuario

    async getUser() {
        let data = { IdUsuario: this.state.id }
        const response = await request.post("/admin/administracion/get/user", data);

        if (response && !response.error) {
            if (response.usuario && !response.empty) {
                this.setState({

                    nombre: response.usuario[0].nombre,
                    usuario: response.usuario[0].usuario,
                    password: response.usuario[0].password,
                    idUsuario: response.usuario[0].id_usuario
                });
            } else {
                this.setState({ empty: true, message: response.message });
            }
        } else {
            this.setState({ error: true, message: response.message });
        }

    }
    // GUARDA FORMULARIO
    async handledSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;

        let data = {


            Nombre: form.name.value,
            Usuario: form.usuario.value,
            Password: form.password.value,
            Perfil: parseInt(form.perfil.value),
            IdAdministracion: this.state.id,
            IdUsuario: this.state.idUsuario
        };
        this.setState({
            showModalUserEddit: false
        })
        this.props.edditUser(data)

    }
}


export default ModalUserEddit;