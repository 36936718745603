import React, { Component } from 'react';
import { Consumer } from "../../context";
import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import { Link } from "react-router-dom";
import AdminInfoTable from "../../components/adminIgate/administraciondetalle/adminInfo"
import UserTable from "../../components/adminIgate/administraciondetalle/usuarioTable"
import FraccionamientosTable from "../../components/adminIgate/administraciondetalle/FracDetalle"

class AdminDetail extends Component {
    state = {}
    render() {
        return (
            <div className="houses column">
                <Helmet>
                    <title>iGate - Admin</title>
                </Helmet>
                <Header />
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-16" />

                        <div className="navigation align-center">

                            <div className="justify-start">
                                <div>
                                    <Link to="/admin" className="font-regular color-white">
                                        <button type="button" className="btn btn-secondary btn-small color-white">
                                            <i className="fas fa-arrow-left font-text" />
                                            &nbsp; Regresar
								        </button>

                                    </Link>

                                </div>

                            </div>

                        </div>


                        <div className="white-space-16" />

                        <AdminInfoTable></AdminInfoTable>
                        <div className="white-space-16" />
                        <UserTable></UserTable>
                        <div className="white-space-16" />

                        <FraccionamientosTable></FraccionamientosTable>
                        <div className="white-space-24" />


                    </div>

                </div>

            </div>

        );
    }
}

export default Consumer(AdminDetail);