/**
 *  incidents-table.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de incidencias
 */

import React, { Component } from "react";
import ReactModal from "react-modal";
import { Consumer } from "../../context";
import moment from "moment";
import Request from "../../core/httpClient";

const request = new Request();
ReactModal.setAppElement("#root");

class IncidentsTable extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      incidentes: [],
      incidenteDetalle: [],
      resolucion: 0
    };
  }

  render() {
    return (
      <div className="column">
        <div className="filters align-center">
          <p className="font-regular weight-medium color-dark">Filtrar:</p>
          <select name="filter-incidents" className="input-select">
            <option value="0">Todo</option>
            <option value="1">Sin resolver</option>
            <option value="2">Resueltos</option>
          </select>
        </div>
        <div className="white-space-16" />
        <div className="card-table card-incidents column" id="card-owners">
          <div className="title">
            <h4>Tabla de Incidencias</h4>
          </div>
          <div className="table-responsive">
            <table>
              <thead>
                <tr className="text-left">
                  <th className="type">Tipo</th>
                  <th className="name">Reportado</th>
                  <th className="date text-center">Fecha</th>
                  <th className="description">Descripción</th>
                  <th className="resolution text-center">Resolución</th>
                  <th className="more text-center" />
                </tr>
              </thead>
              <tbody>
                {this.state.incidentes.map((incidente, key) => (
                  <tr className="tr" key={key}>
                    <td className="weight-semi">{incidente.tipo}</td>
                    <td className="description-detail">{incidente.nombre}</td>
                    <td className="text-center">
                      {moment(incidente.fecha).format("YYYY-MM-DD HH:MM")}
                    </td>
                    <td className="description text-justify">
                      {incidente.descripcion}
                    </td>
                    <td className="text-center weight-semi">
                      {incidente.resolucion_descripcion}
                    </td>
                    <td className="text-center weight-semi">
                      <button
                        className="font-small weight-semi"
                        onClick={this.handleOpen.bind(this, incidente.id_queja)}
                      >
                        Ver detalles
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactModal
            isOpen={this.state.showModal}
            className="modal modal-incidents column"
            overlayClassName="overlay"
          >
            <form className="flex justify-center">
              <div className="container column">
                <div className="close-modal justify-end">
                  <button
                    className="btn-modal-close color-dark"
                    onClick={this.handleClose.bind(this)}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <h3>Detalle completo de la incidencia</h3>
                <div className="white-space-8" />
                <p>
                  <b>Tipo de incidencia:</b> {this.state.incidenteDetalle.tipo}
                </p>
                <div className="white-space-8" />
                <p>
                  <b>Fecha de reporte:</b>{" "}
                  {moment(this.state.incidenteDetalle.fecha).format(
                    "YYYY-MM-DD HH:MM"
                  )}
                </p>
                <div className="white-space-8" />
                <p>
                  <b>Reportado por:</b> {this.state.incidenteDetalle.nombre}
                </p>
                <div className="white-space-8" />
                <p>
                  <b>Dirección:</b> {this.state.incidenteDetalle.direccion}
                </p>
                <div className="white-space-8" />
                <p>
                  <b>Teléfono:</b> {this.state.incidenteDetalle.telefono}
                </p>
                <div className="white-space-8" />
                <p>
                  <b>Descripción:</b> {this.state.incidenteDetalle.descripcion}
                </p>
                <div className="white-space-8" />
                <p>
                  <b>Imagen:</b>
                </p>
                <div className="white-space-4" />
                <div className="responsive-img">
                  <img src={this.state.incidenteDetalle.imagen} alt="" />
                </div>
                <div className="white-space-8" />
                <p>
                  <b>Resolución:</b>
                </p>
                <div className="white-space-4" />
                <select
                  name="incident-resolution"
                  id="incident-resolution"
                  className="input-select"
                  value={this.state.resolucion}
                >
                  <option value="0">Sin resolver</option>
                  <option value="1">En revision</option>
                  <option value="2">Resuelto</option>
                </select>
                <div className="white-space-16" />
                <p>
                  <b>Descripción de la resolución:</b>
                </p>
                <div className="white-space-4" />
                <textarea className="textarea" name="description" placeholder="Descripción de la resolución (600 caracteres máximo)" cols="30" rows="8" maxLength="600" minLength="20"></textarea>
                <div className="white-space-16" />
                <div className="btn-container">
                  <button type="submit" className="btn btn-primary color-white">
                    <i className="fas fa-check" /> ACTUALIZAR
                  </button>
                  <button className="btn btn-secondary color-white">
                    <i className="fas fa-times" /> CERRAR
                  </button>
                </div>
                <div className="white-space-16" />
              </div>
            </form>
          </ReactModal>
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.load();
  }

  async load() {
    const user = await this.props.context.loadUser();
    if (user) {
      this.setState({
        user: {
          idUsuario: user.id.id_usuario,
          idPerfil: user.id.id_perfil,
          idFraccionamiento: user.id.id_fraccionamiento
        }
      });
      this.getIncidenciasPage();
    }
  }

  getIncidenciasPage() {
    this.getIncidencias();
  }

  // TRAER INCIDENCIAS TOTALES
  async getIncidencias() {
    this.setState({ loadingIncidencias: true });
    let data = {
      IdFraccionamiento: this.state.user.idFraccionamiento
    };

    const response = await request.post("/incidencias/get/total", data);

    if (response && !response.error) {
      if (response.incidenciasTotales && !response.empty) {
        this.setState({
          incidentes: response.incidenciasTotales,
          loadingIncidencias: false
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loadingIncidencias: false
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loadingIncidencias: false
      });
    }
  }

  handleOpen(idQueja) {
    this.setState({ showModal: true });
    // Do HTTP request here
    this.getIncidenciaDetalle(idQueja);
  }

  // handleChange(event) {
  //   //console.log(event.target.value);
  //   this.setState({resolucion: event.target.value});

  //   // Do HTTP request here

  // }
  // async handleSubmit(event){
  //   event.preventDefault();
  //   let data={
  //     idQueja:this.state.idQueja,
  //     resolucion: this.state.resolucion,
  //     resolucionDetalle:

  //   }
  // }

  handleClose() {
    this.setState({ showModal: false, incidenteDetalle: "" });
    // Unset the state
  }

  //   traer una unica incidencia
  async getIncidenciaDetalle(idQueja) {
    let data = {
      idQueja: idQueja
    };

    const response = await request.post("/incidencias/get/detalle", data);
    ////console.log("response: ", response);

    if (response && !response.error) {
      if (response.incidenciaDetalle && !response.empty) {
        this.setState({
          incidenteDetalle: response.incidenciaDetalle,
          resolucion: response.incidenciaDetalle.resolucion
        });
      } else {
        this.setState({
          empty: true,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
      });
    }
  }
  // Actualizar incidencia status
  async updateIncidenciaDetalle(idQueja, resolucion) {
    let data = {
      IdQueja: idQueja,
      resolucion: resolucion
    };

    const response = await request.post("/incidencias/get/detalle", data);

    if (response && !response.error) {
      if (response.incidenciaDetalle && !response.empty) {
        this.setState({
          incidenteDetalle: response.incidenciaDetalle[0]
        });
      } else {
        this.setState({
          empty: true,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
      });
    }
  }
}

export default Consumer(IncidentsTable);
