/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de todos los propietarios
 */

import React, { Component } from "react";
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import OwnersTable from "../components/propietarios/Owners-Table";
import ReactModal from "react-modal";
import Request from "../core/httpClient";
const request = new Request();

class Owners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalOwners: false,
      user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
      houses: [],
      error: false,
      reload: false,
      propietarios: [],
      subfraccionamientos: [],
      idSubfrac: 0
    };
    this.handleChange = this.handleChange.bind(this)
  }

  render() {
    return (
      <div className="owners column">
        <Helmet>
          <title>iGate - Propietarios</title>
        </Helmet>
        <Header />
        <div className="justify-center">
          <div className="container column">
            <div className="white-space-16" />
            <div className="navigation align-center">
              <div className="justify-start">
                <h3 className="weight-semi">Propietarios</h3>
              </div>
              <div className="justify-end">
                <button
                  type="button"
                  className="btn btn-primary btn-small color-white"
                  onClick={this.handleOpen.bind(this)}
                >
                  <i className="fas fa-plus font-text" />
                  &nbsp; Añadir propietario
                </button>
              </div>
            </div>
            <div className="white-space-16" />
            <OwnersTable
              reload={this.state.reload}
              data={this.state.propietarios}
            />
            <div className="white-space-32" />
          </div>
        </div>

        {
          <ReactModal
            isOpen={this.state.showModalOwners}
            className="modal modal-houses column"
            overlayClassName="overlay"
          >
            <form
              className="flex justify-center"
              onSubmit={this.handledSubmit.bind(this)}
            >
              <div className="container column">
                <div className="close-modal justify-end">
                  <button
                    className="btn-modal-close color-dark"
                    onClick={this.handleClose.bind(this)}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <h3>Nuevo propietario</h3>
                <div className="white-space-16" />
                <div className="column">
                  <div className="align-center">
                    <p>
                      <b>Nombre del propietario:</b>
                    </p>
                    <input
                      type="text"
                      name="fullName"
                      className="input"
                      placeholder="Nombre completo"
                      required
                      minLength="6"
                      maxLength="64"
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Condominio:</b>
                    </p>

                    <select name="idSubfrac" id="sub" className="input input-select" onChange={this.handleChange}>
                      {this.state.subfraccionamientos.map((sub, key) => (
                        <option key={key} value={sub.id_subfraccionamiento}>
                          {sub.nombre}
                        </option>

                      ))}
                    </select>
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Vivienda:</b>
                    </p>

                    <select name="idVivienda" className="input input-select">
                      {this.state.houses.map((house, key) => (
                        <option key={key} value={house.id_vivienda}>
                          {house.numero_registro}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="white-space-24" />
                  <h5 className="weight-semi">
                    Datos para contactar al propietario
       </h5>
                  <div className="white-space-24" />
                  <div className="align-center">
                    <p>
                      <b>Dirección:</b>
                    </p>
                    <input
                      type="text"
                      name="address"
                      className="input"
                      placeholder="Dirección de contacto"
                      required
                      minLength="6"
                      maxLength="150"
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Municipio:</b>
                    </p>
                    <input
                      type="text"
                      name="municipality"
                      className="input"
                      placeholder="Municipio"
                      required
                      minLength="6"
                      maxLength="80"
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Estado:</b>
                    </p>
                    <input
                      type="text"
                      name="state"
                      className="input"
                      placeholder="Estado"
                      required
                      minLength="6"
                      maxLength="80"
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>C.P:</b>ç
         </p>
                    <input
                      type="text"
                      name="zip"
                      className="input"
                      placeholder="Código postal"
                      required
                      maxLength="10"
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Teléfono:</b>
                    </p>
                    <input
                      type="text"
                      name="phone"
                      className="input"
                      placeholder="Teléfono"
                      required
                      maxLength="24"
                    />
                  </div>
                </div>
                <div className="white-space-32" />
                <div className="btn-container">
                  <button type="submit" className="btn btn-primary color-white">
                    {this.state.loading ? (
                      <i className="fas fa-spinner fa-spin" />
                    ) : (
                        <span>
                          <i className="fas fa-check" />
                          &nbsp; REGISTAR PROPIETARIO
           </span>
                      )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary color-white"
                    onClick={this.handleClose.bind(this)}
                  >
                    <i className="fas fa-times" />
                    &nbsp; CANCELAR
       </button>
                </div>
              </div>
            </form>
          </ReactModal>
        }

      </div>
    );
  }

  componentDidMount() {
    this.load();
  }
  async load() {
    const user = await this.props.context.loadUser();
    if (user) {
      this.setState({
        user: {
          idUsuario: user.id.id_usuario,
          idPerfil: user.id.id_perfil,
          idFraccionamiento: user.id.id_fraccionamiento
        }
      });
      this.getPropietarioFraccionamiento();
      this.getSubFraccionamientos();


    }
  }
  async handleChange() {

    let idSub = document.getElementById("sub").value

    this.getHouses(idSub)


  }
  //traer Propietarios del fraccionaiento
  async getPropietarioFraccionamiento() {
    this.setState({ loadingPropietario: true });
    let data = {
      idFraccionamiento: this.state.user.idFraccionamiento
    };

    const response = await request.post(
      "/fraccionamientos/get/propietarios",
      data
    );

    if (response && !response.error) {
      if (response.propietario && !response.empty) {
        this.setState({
          propietarios: response.propietario
        });
      } else {
        this.setState({
          empty: true,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
      });
    }
  }

  async handledSubmit(event) {
    event.preventDefault();
    this.setState({
      loading: true
    });
    const form = event.target;
    let idv = parseInt(form.idVivienda.value);
    let data = {

      Cp: form.zip.value,
      Direccion: form.address.value,
      Estado: form.state.value,
      IdVivienda: idv,
      Municipio: form.municipality.value,
      Nombre: form.fullName.value,
      Telefono: form.phone.value
    };


    const response = await request.post("/propietarios/crear", data);

    this.setState({ showModalOwners: false });
    if (response && !response.error) {
      if (response.created && !response.empty) {
        this.setState({
          reload: true,
          loading: false
        });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loading: false
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loading: false
      });
    }
    this.getPropietarioFraccionamiento();
  }

  handleOpen() {
    this.setState({ showModalOwners: true });
    this.getHouses(this.state.idSubfrac)
  }

  handleClose() {
    this.setState({ showModalOwners: false });
  }

  // traer viviendas
  async getHouses(idsub) {

    this.setState({ houses: [] })
    let data = {


      idSub: parseInt(idsub)
    };


    const response = await request.post(

      "/fraccionamientos/sub/viviendas",
      data
    );


    if (response && !response.error) {
      if (response.subviviendas && !response.empty) {
        this.setState({
          houses: response.subviviendas
        });

      } else {
        this.setState({
          empty: true,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
      });
    }
  }
  // traer subfraccionamientos
  async getSubFraccionamientos() {
    let data = {
      idFraccionamiento: this.state.user.idFraccionamiento
    };

    const response = await request.post(

      "/fraccionamientos/subfraccionamientos/get/all",
      data
    );

    if (response && !response.error) {
      if (response.subfraccionamientos && !response.empty) {
        this.setState({
          subfraccionamientos: response.subfraccionamientos,
          idSubfrac: response.subfraccionamientos[0].id_subfraccionamiento

        });
        ////console.log(this.state.idSubfrac);



      } else {
        this.setState({
          empty: true,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
      });
    }

  }
}

export default Consumer(Owners);
