/**
 *  httpClient.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Cliente HTTP para todas las peticiones Web
*/

import request from "superagent";
import { URL_API } from './urlsApi';

class Request {

    get(url, data) {
        const result = request
            .get(URL_API + url)
            .query(data)
            .set({ 'igate_key': '5fcae9a7be1dfa53da44c353492bc73d' })
            .then(res => {
                return res.body;
            })
            .catch(err => {
                ////console.log(err.message);
                return { error: true, message: err.message };
            });
        return result;
    }

    post(url, data) {
        const result = request
            .post(URL_API + url)
            .send(data)
            .set({ 'igate_key': '5fcae9a7be1dfa53da44c353492bc73d' })
            .then(res => {
                return res.body;
            })
            .catch(err => {
                ////console.log(err.message);
                return { error: true, message: err.message };
            });
        return result;
    }
}

export default Request;
