import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Request from "../../../core/httpClient";
const request = new Request();


class AdminInfoTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eddit: true,
            id: this.props.location.state.id,
            administracion: [],
            nombreAdmin: '',
            direccionAdmin: '',
            municipioAdmin: '',
            estadoAdmin: '',
            paisAdmin: '',
            contactoAdmin: '',
            telefonoAdmin: '',
            emailAdmin: '',
            usuarios: []
        }

    }


    render() {

        return (

            <div className="card-table card-owners column" id="card-owners">
                <h4>Detalle de la Administración</h4>
                <div className=" justify-end">

                    <button className="btn-modal-close color-dark" type="button" onClick={this.handleEdit.bind(this)}>
                        <i className="fas fa-edit" />
                        Clic para Editar
                    </button>
                </div>
                <div className="white-space-8" />
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th className="text-left">Datos</th>

                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>

                                    <form onSubmit={this.handledSubmit.bind(this)} >
                                        <div className="align-center">
                                            <p> <b>Nombre:</b></p>
                                            <input type="text" name="nameAdministracion" value={this.state.nombreAdmin} onChange={event => this.setState({ nombreAdmin: event.target.value })} className="input" disabled={this.state.eddit} placeholder="Nombre de administracion"
                                                required
                                            />
                                        </div>
                                        <div className="align-center">
                                            <p>
                                                <b>Dirección:</b>
                                            </p>
                                            <input
                                                type="text"
                                                disabled={this.state.eddit}
                                                value={this.state.direccionAdmin}
                                                onChange={event => this.setState({ direccionAdmin: event.target.value })}
                                                name="addressAdministracion"

                                                className="input"
                                                placeholder="Direccion"
                                                required
                                            />
                                        </div>
                                        <div className="align-center">
                                            <p>
                                                <b>Municipio:</b>
                                            </p>
                                            <input
                                                type="text"
                                                disabled={this.state.eddit}
                                                value={this.state.municipioAdmin}
                                                onChange={event => this.setState({ municipioAdmin: event.target.value })}
                                                name="municipalyAdministracion"

                                                className="input"
                                                placeholder="Municipio"
                                                required
                                            />
                                        </div>
                                        <div className="align-center">
                                            <p>
                                                <b>Estado:</b>
                                            </p>
                                            <input
                                                type="text"
                                                disabled={this.state.eddit}
                                                value={this.state.estadoAdmin}
                                                onChange={event => this.setState({ estadoAdmin: event.target.value })}
                                                name="stateAdministracion"

                                                className="input"
                                                placeholder="Nombre completo"
                                                required
                                            />
                                        </div>
                                        <div className="align-center">
                                            <p>
                                                <b>País:</b>
                                            </p>
                                            <input
                                                type="text"
                                                disabled={this.state.eddit}
                                                name="countryAdministracion"
                                                value={this.state.paisAdmin}
                                                onChange={event => this.setState({ paisAdmin: event.target.value })}

                                                className="input"
                                                placeholder="Nombre completo"
                                                required
                                            />
                                        </div>
                                        <div className="align-center">
                                            <p>
                                                <b>Contacto:</b>
                                            </p>
                                            <input
                                                type="text"
                                                disabled={this.state.eddit}
                                                value={this.state.contactoAdmin}
                                                onChange={event => this.setState({ contactoAdmin: event.target.value })}
                                                name="contactAdministaracion"

                                                className="input"
                                                placeholder="Nombre completo"
                                                required
                                            />
                                        </div>
                                        <div className="align-center">
                                            <p>
                                                <b>Teléfono:</b>
                                            </p>
                                            <input
                                                type="text"
                                                disabled={this.state.eddit}
                                                value={this.state.telefonoAdmin}
                                                onChange={event => this.setState({ telefonoAdmin: event.target.value })}
                                                name="phoneAdministracion"

                                                className="input"

                                                placeholder="Nombre completo"
                                                required
                                            />
                                        </div>
                                        <div className="align-center">
                                            <p>
                                                <b>Email:</b>
                                            </p>
                                            <input
                                                type="email"
                                                disabled={this.state.eddit}
                                                value={this.state.emailAdmin}
                                                onChange={event => this.setState({ emailAdmin: event.target.value })}
                                                name="emailAdministracion"

                                                className="input"
                                                placeholder="Nombre completo"
                                                required
                                            />
                                        </div>
                                        <div className="white-space-32" />


                                        {!this.state.eddit ? (
                                            <div className="btn-container">
                                                <button type="submit" className="btn btn-primary color-white">
                                                    <i className="fas fa-check" />
                                                    &nbsp; ACTUALIZAR DATOS
                                                </button>
                                                <button type="button" className="btn btn-secondary color-white" >
                                                    <i className="fas fa-times" />
                                                    &nbsp; CANCELAR
                                                </button>
                                            </div>
                                        ) : null}
                                    </form>
                                </td>


                            </tr>





                        </tbody>
                    </table>

                </div>

            </div>
        );
    }

    componentDidMount() {
        this.getAdministracion()

    }

    handleEdit() {
        this.setState({ eddit: !this.state.eddit })
    }
    handleCancel() {
		this.setState({ add: false, viewOwners: true });
		//this.limpiarState();
	}


    // // TRAER TODAS LAS ADMINISTRACIONES
    async getAdministracion() {

        let data = { IdAdministracion: this.state.id };

        const response = await request.post("/admin/administracion/get/administracion", data);

        if (response && !response.error) {
            if (response.administracion && !response.empty) {
                this.setState({
                    administracion: response.administracion,
                    nombreAdmin: response.administracion[0].nombre,
                    direccionAdmin: response.administracion[0].direccion,
                    municipioAdmin: response.administracion[0].municipio,
                    estadoAdmin: response.administracion[0].estado,
                    paisAdmin: response.administracion[0].pais,
                    contactoAdmin: response.administracion[0].contacto,
                    telefonoAdmin: response.administracion[0].telefono,
                    emailAdmin: response.administracion[0].email,


                });

            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
    }
    // GUARDA FORMULARIO
    async handledSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;

        let data = {

            IdAdministracion: this.state.id,
            Nombre: form.nameAdministracion.value,
            Direccion: form.addressAdministracion.value,
            Municipio: form.municipalyAdministracion.value,
            Estado: form.stateAdministracion.value,
            Pais: form.countryAdministracion.value,
            NombreContacto: form.contactAdministaracion.value,
            Telefono: form.phoneAdministracion.value,
            email: form.emailAdministracion.value
        };

        const response = await request.post("/admin/administracion/eddit/administracion", data);

        this.setState({ showModalAddAdmin: false });
        if (response && !response.error) {
            if (response.eddited && !response.empty) {
                this.setState({

                    eddit: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }

        this.setState({ eddit: !this.state.eddit })
    }


}

export default withRouter(AdminInfoTable);