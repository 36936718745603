import React, { Component } from 'react';
import { Consumer } from "../context";
import { Helmet } from "react-helmet";
import ReactModal from "react-modal";
import Request from "../core/httpClient";
import Header from "../components/header/header";
import AdminTable from '../components/adminIgate/administracion/admintable';
import HousesTable from '../components/services/services-table';
const request = new Request();


class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {

            showModalAddAdmin: false,
            loading: false,
            administraciones: [],
            user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 }
        }
    }

    render() {
        return (
            <div className="houses column">
                <Helmet>
                    <title>AppMosphera - Admin</title>
                </Helmet>
                <Header />
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-16" />

                        <div className="navigation align-center">
                            <div className="justify-start">
                                <h3 className="weight-semi">Administraciones</h3>
                            </div>
                            <div className="justify-end">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-small color-white"
                                    onClick={this.handleOpen.bind(this)} >
                                    <i className="fas fa-plus font-text" />
                                    &nbsp; Añadir Administración
								</button>
                            </div>

                        </div>
                        <div className="white-space-16" />
                        <AdminTable dataAdmin={this.state.administraciones} />
                    </div>
                </div>

                {/* modal agregar administracion */}
                <ReactModal
                    isOpen={this.state.showModalAddAdmin}
                    className="modal modal-houses column"
                    overlayClassName="overlay" >
                    <form className="flex justify-center" onSubmit={this.handledSubmit.bind(this)} >
                        <div className="container column">
                            <div className="close-modal justify-end">
                                <button className="btn-modal-close color-dark" onClick={this.handleClose.bind(this)} >
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <h3>Nueva Administración</h3>
                            <div className="white-space-16" />
                            <div className="column">
                                <div className="align-center">
                                    <p>
                                        <b>Nombre de la empresa:</b>
                                    </p>
                                    <input type="text" name="companyName" className="input" placeholder="Nombre completo" required />
                                </div>

                                <div className="white-space-24" />
                                <h5 className="weight-semi">
                                    Datos de la empresa
                                 </h5>
                                <div className="white-space-24" />
                                <div className="align-center">
                                    <p>
                                        <b>Dirección:</b>
                                    </p>
                                    <input type="text" name="companyAddress" className="input" placeholder="Dirección de contacto" required />
                                </div>
                                <div className="white-space-8" />
                                <div className="align-center">
                                    <p>
                                        <b>Municipio:</b>
                                    </p>
                                    <input type="text" name="municipality" className="input" placeholder="Municipio" required />
                                </div>
                                <div className="white-space-8" />
                                <div className="align-center">
                                    <p>
                                        <b>Estado:</b>
                                    </p>
                                    <input type="text" name="state" className="input" placeholder="Estado" required />
                                </div>
                                <div className="white-space-8" />
                                <div className="align-center">
                                    <p>
                                        <b>País:</b>
                                    </p>
                                    <input type="text" name="country" className="input" placeholder="pais" required />
                                </div>

                                <div className="white-space-8" />
                                <h5 className="weight-semi">
                                    Datos de contacto
                                 </h5>
                                <div className="white-space-8" />
                                <div className="align-center">
                                    <p>
                                        <b>Contacto:</b>
                                    </p>
                                    <input type="text" name="contact" className="input" placeholder="Nombre contacto" required maxLength="24" />
                                </div>
                                <div className="align-center">
                                    <p>
                                        <b>Teléfono:</b>
                                    </p>
                                    <input type="text" name="phone" className="input" placeholder="Teléfono" required maxLength="24" />
                                </div>
                                <div className="align-center">
                                    <p>
                                        <b>Email:</b>
                                    </p>
                                    <input type="email" name="email" className="input" placeholder="email" required maxLength="24" />
                                </div>
                            </div>
                            <div className="white-space-32" />
                            <div className="btn-container">
                                <button type="submit" className="btn btn-primary color-white">
                                    {this.state.loading ? (<i className="fas fa-spinner fa-spin" />) : (<span> <i className="fas fa-check" /> &nbsp; REGISTAR</span>)}
                                </button>
                                <button type="button" className="btn btn-secondary color-white" >
                                    <i className="fas fa-times" />  &nbsp; CANCELAR
                               </button>
                            </div>
                        </div>
                    </form>
                </ReactModal>

                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-16" />

                        <div className="navigation align-center">
                            <div className="justify-end">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-small color-white"
                                    onClick={this.handleOpen.bind(this)} >
                                    <i className="fas fa-plus font-text" />
                                    &nbsp; Agregar Proveedor de Servicio
								</button>
                            </div>

                        </div>
                        <div className="white-space-16" />
                        <HousesTable dataAdmin={this.state} />
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.load()
    }
    async load() {
        const user = await this.props.context.loadUser();
        if (user) {
            this.setState({
                user: {
                    idUsuario: user.id.id_usuario,
                    idPerfil: user.id.id_perfil,
                    idFraccionamiento: user.id.id_fraccionamiento
                }
            });
        }

        this.getAdministraciones();

    }
    // ABRE MODAL
    handleOpen() {
        this.setState({ showModalAddAdmin: true });
    }
    // GUARDA FORMULARIO
    async handledSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;

        let data = {

            Nombre: form.companyName.value,
            Direccion: form.companyAddress.value,
            Municipio: form.municipality.value,
            Estado: form.state.value,
            Pais: form.country.value,
            NombreContacto: form.contact.value,
            Telefono: form.phone.value,
            email: form.email.value
        };

        const response = await request.post("/admin/administracion/create", data);

        this.setState({ showModalAddAdmin: false });
        if (response && !response.error) {
            if (response.created && !response.empty) {
                this.setState({

                    loading: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }
        this.getAdministraciones()

    }
    // CIERRA MODAL
    handleClose() {
        this.setState({ showModalAddAdmin: false });
    }
    // // TRAER TODAS LAS ADMINISTRACIONES
    async getAdministraciones() {

        let data = {
            idPerfil: this.state.user.idPerfil
        };


        const response = await request.post("/admin/administracion/get/all", data);


        if (response && !response.error) {
            if (response.administraciones && !response.empty) {
                this.setState({
                    administraciones: response.administraciones
                });



            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
    }

}

export default Consumer(Admin);