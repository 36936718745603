/**
 *  incidents.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de Visitas incidencias del día
 */

import React, { Component } from 'react';
import moment from 'moment';

class Incidents extends Component {
	render() {
		let incidenciasDiarias = [];

		if (this.props.incidentesHoy) {
			incidenciasDiarias = this.props.incidentesHoy;
		}

		return (
			<div className="incidents">
				<div className="card-table card-incident column">
					<div className="title">
						<h4>Incidencias del día</h4>
					</div>
					{this.props.incidentesHoy ? (
						<div className="table-responsive">
							<table>
								<thead>
									<tr className="text-left">
										<th className="name">Nombre</th>
										<th className="description">Descripción</th>
										<th className="vivienda text-center">Vivienda</th>
										<th className="date text-center">Hora</th>
									</tr>
								</thead>
								<tbody>
									{incidenciasDiarias.map((incidencia, key) => (
										<tr key={key}>
											<td>{incidencia.nombre}</td>
											<td className="description-detail">{incidencia.descripcion}</td>
											<td className="text-center">{incidencia.numero_registro}</td>
											<td className="text-center">
												{moment('1900-01-01 ' + incidencia.hora).format('HH:mm')}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<b>Sin incidencias</b>
					)}
				</div>
			</div>
		);
	}
}

export default Incidents;
