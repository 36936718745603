import React, { Component } from 'react';
import { Link } from "react-router-dom";

class AdminTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            foo: ""
        }
    }

    render() {
        let administraciones = [];

        if (this.props.dataAdmin) {
            administraciones = this.props.dataAdmin;
        }
        return (
            <div className="card-table card-owners column" id="card-owners">
                <h4>Administraciones</h4>
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="name">Administración</th>

                                <th className="vivienda ">Estado</th>
                                <th className="description text-center">Dirección</th>
                                <th className="date text-center">Teléfono</th>
                                <th className="date text-center" />
                            </tr>
                        </thead>
                        <tbody>
                            {administraciones.map((admin, key) => (
                                <tr key={key}>
                                    <td>{admin.nombre}</td>
                                    <td>{admin.estado}</td>
                                    <td className="text-center">{admin.direccion}</td>
                                    <td className="">{admin.telefono}</td>
                                    <td className="text-center weight-semi cursor-pointer">

                                        <div>
                                            <Link
                                                data={admin.id_administracion}
                                                to={{ pathname: '/administracion/detalle', state: { id: admin.id_administracion } }}
                                                // to="/administracion/detalle"
                                                className="font-regular color-white">

                                                <p className="fas fa-plus-circle"> Ver Más..</p>
                                            </Link>

                                        </div>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>);
    }



}

export default AdminTable;