/**
 *  incidents-table.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de incidencias
 */

import React, { Component } from "react";
import { Consumer } from "../../context";
import moment from "moment";
import DinamicModal from '../dinamicModal/dinamicModal';
import NotificacionForm from '../notifications/form';
import Paginador from '../paginador/paginador';

class IncidentsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificacion: {},
            page: 1
        };
    }

    handleOpen(idModal, notificacion) {
        this.setState({ showModal: true, notificacion });
        const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
        }
        this.setState({isModalOpen: true });
        const input = document.getElementById('name');
        if (input) {
            input.focus();
        }
    }

    render() {
        let notificaciones = [];
        if (Array.isArray(this.props.notificaciones)) {
            notificaciones = this.props.notificaciones;
        }
        return (
            <div className="column">
                <div className="card-table card-incidents column" id="card-owners">
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="text-left">Título</th>
                                <th className="text-left">Fecha</th>
                                <th className="text-left">Aplicación</th>
                                <th className="text-left">Fraccionamiento</th>
                                <th className="text-left">Subfraccionamiento</th>
                                <th className="text-left">Vivienda</th>
                                <th className="text-center" />
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.loading? 
                                <tr>
                                    <td colSpan="5">
                                        <div className="row justify-center align-center">
                                            <i className="fas fa-spinner fa-spin"></i>
                                        </div>
                                    </td>
                                </tr>
                                : notificaciones.slice(((this.state.page - 1) * 12), this.state.page * 12).map((notificacion, key) => (
                                <tr key={key}>
                                    <td className="text-left">
                                        {notificacion.titulo}
                                    </td>
                                    <td className="text-left">
                                        {moment(notificacion.fecha).format("DD/MM/YYYY HH:mm")}
                                    </td>
                                    <td className="text-left">
                                        {notificacion.aplicacion}
                                    </td>
                                    <td className="text-left">
                                        {notificacion.fraccionamiento}
                                    </td>
                                    <td className="text-left">
                                        {notificacion.subfraccionamiento}
                                    </td>
                                    <td className="text-left">
                                        {notificacion.vivienda}
                                    </td>
                                    <td className="text-center">
                                        <button
                                            className="font-small weight-semi"
                                            onClick={this.handleOpen.bind(this, 'modal-detalle-notificacion', notificacion)}
                                        >
                                            Ver detalles
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Paginador
                    setPage = {(page) => this.setState({page})}
                    pages   = {Math.ceil(notificaciones.length / 12)}/>
                <DinamicModal
                    idModal             =   {'modal-detalle-notificacion'}
                    isOpen              =   {this.state.showModal}
                    className           =   "modal modal-incidents column"
                    overlayClassName    =   "overlay"
                    title               =   "Detalle Notificación"
                    showBtnCancel       =   {true}
                    btnTextCancel       =   "CERRAR"
                    loadingSave         =   {this.state.loadingSave}
                    closeModal          =   {() => this.setState({showModal: false})}
                >
                    <NotificacionForm
                        notificacion    =   {this.state.notificacion}
                        isModalOpen     =   {this.state.showModal}
                        formValues      =   {(formValues) => this.setState({formValues})}/>
                </DinamicModal>
            </div>
        </div>
        );
    }
}

export default Consumer(IncidentsTable);
