import React, { Component } from 'react';

class ModalBorrar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    render() {
        let id = 0;
        if (this.props.id) {
            id = this.props.id
        }
        return (
			<div className="overlay-modal row hide-modal" id={this.props.name || 'deleteModal'}>
				<div className="modal-delete">
					<form className="flex justify-center">
						<div className="full column align-center">
							<div className="white-space-32"></div>
							<div className="column container">
								<i className="fas fa-exclamation-circle color-primary fa-5x text-center"></i>
								<div className="white-space-24"></div>
								<h3 className="text-center">¿Estás seguro que deseas eliminar el registro?</h3>
							</div>
							<div className="white-space-32"></div>
							<div className="full">
								{this.state.loading?
									<button type="button" className="btn-modal-delete-success">
										<i className="fas fa-spinner fa-spin"></i>
									</button>
									:
									<button type="button" className="btn-modal-delete-success" onClick={this.handleConfirm.bind(this, id)} >
										<i className="fas fa-check" /> &nbsp; ELIMINAR
									</button>
								}
								<button type="button" className="btn-modal-delete-cancel" onClick={this.closeModal.bind(this)} >
									<i className="fas fa-times" /> &nbsp; CERRAR
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
        );
	}
	
	openModal() {
		let modal = document.getElementById(this.props.name || 'deleteModal');
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}
   
	closeModal() {
		this.setState({loading: false});
		const modal = document.getElementById(this.props.name || 'deleteModal') 
		if(modal) {
			modal.classList.remove('show-modal');
			modal.classList.add('hide-modal');
		}
	}
  
    async handleConfirm(id) {
		this.setState({loading: true});
		await this.props.delete(id, this.closeModal.bind(this));
    }
}

export default ModalBorrar;