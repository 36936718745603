import React, { Component } from 'react';
import { Consumer } from "../../context";
//import Request from "../../core/httpClient";
// const request = new Request();


class Controls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fraccionamientos: [],
			subfraccionamientos: [],
			subfraccionamiento: localStorage.getItem('subfrac') || '0',
            showModal: false,
            hidden: false,
            page: 1,
            filterData: [],
			keys: [],
			dataSet: false,
			filter: '',
			newButton: true,
			selectSub: true,
			btnAux: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.context !== this.props.context) {
            this.loadFraccionamientos();
        }
    }

    async loadFraccionamientos() {
        const { context } = this.props;
        const fraccionamientos = context.fraccionamientos;
        if (Array.isArray(fraccionamientos)) {
            this.setState({fraccionamientos});
        }
    }

    filterSearch() {

    }

    onKeyUp() {

    }

    render() {
        return (
			<div className="container column full">
				<div className="navigation align-center">
					<div className="justify-start">
						<h3 className="weight-semi">{this.props.title}</h3>
					</div>
				</div>
				<div className="row justify-between align-center">
                    <div className="column align-start justify-center">
                        <div className="row align-center">
                            <div className="column">
                                <div className="row align-center">
                                    <select name="fraccionamiento" id="fraccionamiento" className="input input-select"
                                        value={this.state.fraccionamiento}
                                        onChange={(event) => {
                                            if (this.props.fracChange) {
                                                this.props.fracChange(event.target.value)
                                            }
                                        }}>
                                            <option value="0">{this.props.labelDefault ?this.props.labelDefault :'Todos los fraccionamientos'}</option>
                                        {  
                                            this.state.fraccionamientos.map((fraccionamiento, key) => (
                                            <option value={fraccionamiento.id_fraccionamiento} key={key}>{fraccionamiento.nombre}</option>)) 
                                        }
                                    </select> &nbsp;
                                </div>
                            </div>
                            <div className="column">
                                <div className="row align-center">
                                    <select name="subfraccionamiento" id="subfraccionamiento" className="input input-select"
                                        value={this.state.subfraccionamiento}
                                        onChange={(event) => {
                                            if (this.props.subChange) {
                                                this.props.subChange(event.target.value)
                                            }
                                        }}>
                                            <option value="0">{this.props.labelDefault ?this.props.labelDefault :'Todos los subfraccionamientos'}</option>
                                        {  
                                            this.state.subfraccionamientos.map((subfraccionamiento, key) => (
                                            <option value={subfraccionamiento.id_subfraccionamiento} key={key}>{subfraccionamiento.nombre}</option>)) 
                                        }
                                    </select> &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
					<div className="column align-end justify-center">
						<div className="row align-center justify-end">
						    <p className="font-regular weight-medium color-dark">Filtrar:</p>&nbsp;
							<div className="input-clean">
								<input
									className="input-filter"
									type="text"
									name="textFilter"
									id="filterData"
									value={this.state.filter}
									onChange={(event) => this.setState({filter: event.target.value})}>
								</input>&nbsp;
								<i className="fas fa-times" onClick={() => {
									this.setState({filter:''});
								}}></i>
							</div>
							<button className="btn btn-filter btn-small" onClick={(event) => {
                                    if (this.props.setFilter) {
                                        this.props.setFilter(this.state.filter)}
                                    }
                                }>
								<i className="fas fa-search font-small">
								</i> &nbsp; Buscar
							</button>&nbsp;
							{this.props.newButton ?
								<button type="button" className="btn btn-primary btn-small color-white" onClick={() => {
                                    if (this.props.newEvent) {
                                        this.props.newEvent();
                                    }
                                }}>
									<i className="fas fa-plus font-text" />
									&nbsp; Nuevo
								</button> 
								 :
								<button type="button" className="btn btn-disable btn-small color-white">
                                    <i className="fas fa-plus font-text" />
                                    &nbsp; Nuevo
                                </button>
							}							
						</div>
					</div>
				</div>
			</div>
        );
    }
}

export default Consumer(Controls);