/**
 *  owners.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Tablas de propietarios
 */

import React, { Component } from "react";
import ReactModal from "react-modal";
import { Consumer } from "../../context";
import Request from "../../core/httpClient";
const request = new Request();
ReactModal.setAppElement("#root");

class OwnersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
      propietarios: [],
      inquilino: [],
      propietario: [],
      propietarioDetalle: [],
      pNombre: "",
      pRegistro: "",
      pDireccion: "",
      pMunicipio: "",
      pEstado: "",
      pCp: "",
      pTelefono: "",
      idPropietario: [],
      edit: false
    };
  }

  render() {
    let propietarios = [];
    if (this.props.data) {
      propietarios = this.props.data;
    }
    return (
      <div className="card-table card-owners column" id="card-owners">
        <h4>Viviendas</h4>
        <div className="table-responsive">
          <table>
            <thead>
              <tr className="text-left">
                <th className="name">Nombre</th>
                <th className="description">Dirección</th>
                <th className="vivienda text-center">N° de vivienda</th>
                <th className="date text-center">Teléfono</th>
                <th className="date text-center" />
              </tr>
            </thead>
            <tbody>
              {propietarios.map((propietario, key) => (
                <tr key={key}>
                  <td>{propietario.nombre}</td>
                  <td className="description-detail">
                    {propietario.direccion}
                  </td>
                  <td className="text-center">{propietario.numero_registro}</td>
                  <td className="text-center">{propietario.telefono}</td>
                  <td
                    className="text-center weight-semi cursor-pointer"
                    onClick={this.handleOpen.bind(
                      this,
                      propietario.id_propietario,
                      propietario.id_vivienda
                    )}
                  >
                    Ver más
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {
          <ReactModal
            isOpen={this.state.showModal}
            className="modal modal-houses column"
            overlayClassName="overlay"
          >
            <form
              className="flex justify-center"
              onSubmit={this.handledSubmit.bind(this)}
            >
              <div className="container column">
                <div className="close-modal justify-end">
                  <button
                    className="btn-modal-close color-dark"
                    onClick={this.handleClose.bind(this)}
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
                <div>
                  <h3>Datos del propietario</h3>
                  <button
                    type="button"
                    id="btn-modal-edit"
                    onClick={this.handleEdit.bind(this)}
                  >
                    &nbsp; <i className="font-text fas fa-edit" /> Click para
                    editar
                  </button>
                </div>
                <div className="white-space-16" />
                <div className="column">
                  <div className="align-center">
                    <p>
                      <b>Nombre propietario:</b>
                    </p>
                    <input
                      type="text"
                      name="fullName"
                      value={this.state.pNombre}
                      onChange={event =>
                        this.setState({ pNombre: event.target.value })
                      }
                      className="input"
                      placeholder="Nombre completo"
                      required
                      minLength="6"
                      disabled={!this.state.edit}
                      maxLength="64"
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Vivienda:</b>
                    </p>
                    <input
                      type="text"
                      name="vivienda"
                      disabled
                      className="input"
                      placeholder="Dirección de contacto"
                      required
                      minLength="6"
                      maxLength="150"
                      value={this.state.pRegistro}
                    />
                  </div>
                  <div className="white-space-24" />
                  <h5 className="weight-semi">
                    Datos para contactar al propietario
                  </h5>
                  <div className="white-space-24" />
                  <div className="align-center">
                    <p>
                      <b>Dirección:</b>
                    </p>
                    <input
                      type="text"
                      name="address"
                      disabled={!this.state.edit}
                      className="input"
                      placeholder="Dirección de contacto"
                      onChange={event =>
                        this.setState({ pDireccion: event.target.value })
                      }
                      required
                      minLength="6"
                      maxLength="150"
                      value={this.state.pDireccion}
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Municipio:</b>
                    </p>
                    <input
                      type="text"
                      name="municipality"
                      disabled={!this.state.edit}
                      className="input"
                      placeholder="Municipio"
                      required
                      minLength="6"
                      maxLength="80"
                      value={this.state.pMunicipio}
                      onChange={event =>
                        this.setState({ pMunicipio: event.target.value })
                      }
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Estado:</b>
                    </p>
                    <input
                      type="text"
                      name="state"
                      className="input"
                      disabled={!this.state.edit}
                      placeholder="Estado"
                      required
                      minLength="6"
                      maxLength="80"
                      value={this.state.pEstado}
                      onChange={event =>
                        this.setState({ pEstado: event.target.value })
                      }
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>C.P:</b>
                    </p>
                    <input
                      type="text"
                      name="zip"
                      className="input"
                      disabled={!this.state.edit}
                      placeholder="Código postal"
                      required
                      maxLength="10"
                      value={this.state.pCp}
                      onChange={event =>
                        this.setState({ pCp: event.target.value })
                      }
                    />
                  </div>
                  <div className="white-space-8" />
                  <div className="align-center">
                    <p>
                      <b>Teléfono:</b>
                    </p>
                    <input
                      type="text"
                      name="phone"
                      disabled={!this.state.edit}
                      className="input"
                      placeholder="Teléfono"
                      value={this.state.pTelefono}
                      onChange={event =>
                        this.setState({ pTelefono: event.target.value })
                      }
                      required
                      maxLength="24"
                    />
                  </div>
                </div>
                <div className="white-space-32" />
                <div className="btn-container">
                  <button
                    type="submit"
                    disabled={!this.state.edit}
                    className="btn btn-primary color-white"
                  >
                    <i className="fas fa-check" />
                    &nbsp; ACTUALIZAR DATOS
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary color-white"
                    onClick={this.handleClose.bind(this)}
                  >
                    <i className="fas fa-times" />
                    &nbsp; CANCELAR
                  </button>
                </div>
              </div>
            </form>
          </ReactModal>
        }
        {/*
          this.state.inquilino.map((inquilino, key) => (
            <p key={key}><b>Inquilino:</b> {inquilino.nombre} </p>
          ))
        */}
      </div>
    );
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const user = await this.props.context.loadUser();
    if (user) {
      this.setState({
        user: {
          idUsuario: user.id.id_usuario,
          idPerfil: user.id.id_perfil,
          idFraccionamiento: user.id.id_fraccionamiento
        }
      });
    }
  }

  handleOpen(idPropietario, idVivienda) {
    this.setState({ showModal: true });
    this.getPropietario(idPropietario, idVivienda);
  }
  async handledSubmit(event) {
    event.preventDefault();
    const form = event.target;

    let data = {
      IdPropietario: this.state.propietarioDetalle.id_propietario,
      Nombre: form.fullName.value,
      Direccion: form.address.value,
      Municipio: form.municipality.value,
      Cp: form.zip.value,
      Estado: form.state.value,
      Telefono: form.phone.value
    };
    const response = await request.post("/propietarios/update", data);

    if (response && !response.error) {
      if (response.updated && !response.empty) {
        this.setState({ showModal: false });
      } else {
        this.setState({
          empty: true,
          message: response.message,
          loadingPropietario: false
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message,
        loadingPropietario: false
      });
    }
  }

  handleClose() {
    this.setState({ showModal: false, propietario: "", edit: false });
  }

  // traer un unico propietario
  async getPropietario(idPropietario, idVivienda) {
    let data = { idPropietario: idPropietario, idVivienda: idVivienda };

    const response = await request.post("/propietarios/get/propietario", data);

    if (response && !response.error) {
      if (response.propietario && !response.empty) {
        this.setState({
          propietarioDetalle: response.propietario,

          pNombre: response.propietario.nombre,
          pRegistro: response.propietario.numero_registro,
          pDireccion: response.propietario.direccion,
          pMunicipio: response.propietario.municipio,
          pEstado: response.propietario.estado,
          pCp: response.propietario.cp,
          pTelefono: response.propietario.telefono
        });
      } else {
        this.setState({
          empty: true,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
      });
    }
  }

  //traer Inqulino de la vivienda
  async getInquilino(idInquilino) {
    // this.setState({ loadingPropietario: true });

    let data = {
      idVivienda: idInquilino
    };

    const response = await request.post("/viviendas/vivienda/inquilino", data);

    if (response && !response.error) {
      if (response.inquilinoVivienda && !response.empty) {
        this.setState({
          inquilino: response.inquilinoVivienda
        });
      } else {
        this.setState({
          empty: true,
          message: response.message
        });
      }
    } else {
      this.setState({
        error: true,
        message: response.message
        // loadingPropietario: false
      });
    }
  }

  handleEdit() {
    this.setState({ edit: true });
  }
}

export default Consumer(OwnersTable);
