/**
 *  dashboard.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Inicio del Administrador
 */

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import CardQuick from "../components/dashboard/CardQuick";
import DailyInvitations from "../components/dashboard/daily-invitations";
import Incidents from "../components/dashboard/incidents";
import { Consumer } from "../context";
import moment from "moment";
import Request from "../core/httpClient";
const request = new Request();

class DashBoard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: { idUsuario: 0, idPerfil: 0, idFraccionamiento: 0 },
			invitacionesDiarias: 0,
			invitacionesActivas: 0,
			incidenciasDIa: 0,
			totalViviendas: 0,
			direccion: 0
		};
	}

	render() {
		return (
			<div className="dashboard column">
				<Helmet>
					<title>iGate - Tablero</title>
				</Helmet>
				<Header />
				<div className="justify-center">
					<div className="container column">
						<div className="white-space-16" />
						<div className="navigation align-center">
							<div className="justify-start">
								<h3 className="weight-semi">
									Tablero de Villa Magna
								</h3>
							</div>
							<div className="justify-end">
								<p>
									<span className="weight-semi">iGate</span> >
									Tablero
								</p>
							</div>
						</div>
						<div className="white-space-16" />
						<div className="quick row">
							<CardQuick
								icon="id-card"
								value={this.state.invitacionesDiarias.length}
								loading={
									this.state.loadingDiarios ? true : false
								}
								description="N° de invitaciones del día"
							/>
							<CardQuick
								icon="user-friends"
								value={this.state.invitacionesActivas.length}
								loading={
									this.state.loadingActivos ? true : false
								}
								description="Invitados en el fraccionamiento"
							/>
							<CardQuick
								icon="warehouse"
								value={this.state.totalViviendas.length}
								loading={
									this.state.loadingViviendas ? true : false
								}
								description="N° de viviendas"
							/>
							<CardQuick
								icon="exclamation-triangle"
								value={this.state.incidenciasDIa.length}
								loading={
									this.state.loadingIncidencias ? true : false
								}
								description="N° de incidencias del día"
							/>
						</div>
						<div className="white-space-24" />
						<DailyInvitations
							dataDiaria={this.state.invitacionesDiarias}
							dataActiva={this.state.invitacionesActivas}
						/>
						<div className="white-space-24" />
						<h3 className="weight-semi">Incidencias</h3>
						<div className="white-space-24" />

						<Incidents incidentesHoy={this.state.incidenciasDIa} />

						<div className="white-space-24" />
						<h3 className="weight-semi">Información General</h3>
						<div className="white-space-8" />

						<p className="font-small">
							{this.state.direccion.complete}
						</p>

						<div className="white-space-16" />
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d904.0440746094548!2d-86.84727463302437!3d21.107050693956037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2a35d0ef9589%3A0x58f9179f3f662051!2sAv+Central+de+Villa+Magna%2C+Canc%C3%BAn%2C+Q.R.!5e0!3m2!1ses-419!2smx!4v1560359522383!5m2!1ses-419!2smx"
							width="100%"
							height="400"
							title="mapa"
							frameBorder="0"
							allowFullScreen
						/>
						<div className="white-space-24" />
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const user = await this.props.context.loadUser();
		if (user) {
			this.setState({
				user: {
					idUsuario: user.id.id_usuario,
					idPerfil: user.id.id_perfil,
					idFraccionamiento: user.id.id_fraccionamiento
				}
			});
			this.getDashboard();
		}
	}

	async getDashboard() {
		this.getDiarias();
		this.getActivos();
		this.getIncidencias();
		this.getTotalViviendas();
		this.getDireccion();
	}

	//   traer invitaciones diarias
	async getDiarias() {
		this.setState({ loadingDiarios: true });
		let data = {
			idUsuario: this.state.user.idUsuario,
			idFraccionamiento: this.state.user.idFraccionamiento,
			fecha: moment(Date.now()).format("YYYY-MM-DD")
		};
		const response = await request.post("/invitaciones/get/hoy", data);

		if (response && !response.error) {
			if (response.invitacionesDiarias && !response.empty) {
				this.setState({
					invitacionesDiarias: response.invitacionesDiarias,
					loadingDiarios: false
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loadingDiarios: false
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loadingDiarios: false
			});
		}
	}

	//   traer invitaciones activas
	async getActivos() {
		this.setState({ loadingActivos: true });
		let data = {
			idUsuario: this.state.user.idUsuario,
			idFraccionamiento: this.state.user.idFraccionamiento,
			fechaDia: moment(Date.now()).format("YYYY-MM-DD")
		};
		const response = await request.post("/invitaciones/get/activas", data);
		if (response && !response.error) {
			if (response.invitacionesActivas && !response.empty) {
				this.setState({
					invitacionesActivas: response.invitacionesActivas,
					loadingActivos: false
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loadingActivos: false
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loadingActivos: false
			});
		}
	}

	//   traer incidencias del dia
	async getIncidencias() {
		this.setState({ loadingIncidencias: true });
		let data = {
			fechaDia: moment(Date.now()).format("YYYY-MM-DD"),
			idFraccionamiento: this.state.user.idFraccionamiento
		};
		const response = await request.post("/incidencias/get/dia", data);

		if (response && !response.error) {
			if (response.incidenciasDIa && !response.empty) {
				this.setState({
					incidenciasDIa: response.incidenciasDIa,
					loadingIncidencias: false
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loadingIncidencias: false
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loadingIncidencias: false
			});
		}
	}

	//   traer total de viviendas
	async getTotalViviendas() {
		this.setState({ loadingViviendas: true });
		let data = {
			idFraccionamiento: this.state.user.idFraccionamiento
		};
		const response = await request.post(
			"/fraccionamientos/get/viviendas",
			data
		);

		if (response && !response.error) {
			if (response.fraccionamientosTotalViviendas && !response.empty) {
				this.setState({
					totalViviendas: response.fraccionamientosTotalViviendas,
					loadingViviendas: false
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loadingViviendas: false
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loadingViviendas: false
			});
		}
	}

	//   traer direccion fraccionaiento
	async getDireccion() {
		this.setState({ loadingDireccion: true });
		let data = parseInt(this.state.user.idFraccionamiento);

		const response = await request.get(
			`/fraccionamientos/direccion/${data}`
		);

		if (response && !response.error) {
			if (response.fraccionamientoDireccion && !response.empty) {
				this.setState({
					direccion: response.fraccionamientoDireccion,
					loadingDireccion: false
				});
			} else {
				this.setState({
					empty: true,
					message: response.message,
					loadingDireccion: false
				});
			}
		} else {
			this.setState({
				error: true,
				message: response.message,
				loadingDireccion: false
			});
		}
	}
}

export default Consumer(DashBoard);
