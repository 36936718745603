/**
 *  incidents.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de Inicio de Incidencias
*/

import React, { Component } from 'react';
import { Consumer } from '../context';
import { Helmet } from "react-helmet";
import Header from "../components/header/header";
import IncidentsTable from '../components/incidents/incidents-table';

class Incidents extends Component {

    render() {
        return (
            <div className="incidents column">
                <Helmet>
                    <title>iGate - Incidentes</title>
                </Helmet>
                <Header />
                <div className="justify-center">
                    <div className="container column">
                        <div className="white-space-16" />
                        <div className="navigation align-center">
                            <div className="justify-start">
                                <h3 className="weight-semi">Incidencias</h3>
                            </div>
                            <div className="justify-end">
                                <p>
                                    <span className="weight-semi">iGate</span> > Incidencias
                                </p>
                            </div>
                        </div>
                        <div className="white-space-16" />
                        <IncidentsTable />
                        <div className="white-space-16" />
                    </div>
                </div>
            </div>
        );
    }

}

export default Consumer(Incidents);