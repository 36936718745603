import Request from '../core/httpClient';

const req = new Request();

export async function loadFraccionamientos() {
    const res = await req.post('/admin/fraccionamientos/get', {});
    if (res.fraccionamientos) {
        this.setState({fraccionamientos: res.fraccionamientos});
    } else {
        this.setState({fraccionamientos: []});
    }
}