import React, { useState, useEffect } from 'react';
import { Consumer } from '../../context';

let prevProps = {};

const NotificacionForm = (props) => {
    const [subfraccionamientos, setSubFrac] = useState([]);
    const [viviendas, setViviendas] = useState([]);
    const [disabled, setDisabled] = useState({
        aplicacion: false,
        fraccionamiento: false,
        subfraccionamiento: false,
        vivienda: false,
        titulo: false,
        mensaje: false
    });
    const [formValues, setFormValues] = useState({
        aplicacion: "appmosphera",
        id_fraccionamiento: "0",
        id_subfraccionamiento: "0",
        id_vivienda: "0",
        titulo: "",
        mensaje: ""
    });

    useEffect(() => {
        if (prevProps.isModalOpen !== props.isModalOpen) {
            const {notificacion} = props;
            if (notificacion) {
                setFormValues(notificacion);
                setDisabled({
                    aplicacion: true,
                    fraccionamiento: true,
                    subfraccionamiento: true,
                    vivienda: true,
                    titulo: true,
                    mensaje: true
                });
                if (notificacion.id_subfraccionamiento) {
                    setSubFrac([{id_subfraccionamiento: notificacion.id_subfraccionamiento, nombre: notificacion.subfraccionamiento}]);
                }
                if (notificacion.id_vivienda) {
                    setViviendas([{id_vivienda: notificacion.id_vivienda, numero_registro: notificacion.vivienda}]);
                }
            }
        }
        prevProps = props;
    })

    const { context } = props;
    const fraccionamientos = context.fraccionamientos || [];

    function setValue(key, value) {
        const values = formValues;
        values[key] = value;
        setFormValues(values);
        if (props.formValues) {
            props.formValues(values);
        }
    }

    function fraccionamientoChange(event) {
        setValue('id_fraccionamiento', event.target.value);
        const subfraccionamientos = context.subfraccionamientos.filter(sub => sub.id_fraccionamiento == formValues.id_fraccionamiento);
        setSubFrac(Array.isArray(subfraccionamientos) ? subfraccionamientos : [] );
    }

    function subfraccionamientoChange(event) {
        setValue('id_subfraccionamiento', event.target.value);
        const viviendas = context.viviendas.filter(sub => sub.id_subfraccionamiento == formValues.id_subfraccionamiento);
        setViviendas(Array.isArray(viviendas) ? viviendas : [] );
    }

    return (
        <div className="column align-center">
            <div className="input-form-content row full align-center">
                <div className="column label-big">
                    <p>
                        <b>Aplicación</b>
                    </p>
                </div>
                <div className="row full input-add">
                    <select
                        className="input input-modals"
                        value={formValues.aplicacion}
                        onChange={(event) => setValue('aplicacion', event.target.value)}
                        required
                        disabled={disabled.aplicacion}>>
                        <option value="appmosphera">Appmosphera</option>
                        <option value="web_appmosphera">Appmosphera Web</option>
                        <option value="appmosphera seguridad">Appmosphera Seguridad</option>
                        <option value="igate">iGate</option>
                    </select>
                </div>
            </div>
            <div className="input-form-content row full align-center">
                <div className="column label-big">
                    <p>
                        <b>Fraccionamiento</b>
                    </p>
                </div>
                <div className="row full input-add">
                    <select
                        className="input input-modals"
                        value={formValues.id_fraccionamiento}
                        onChange={fraccionamientoChange.bind(this)}
                        disabled={disabled.fraccionamiento}>>
                        <option value="0">Todos los fraccionamientos</option>
                        {fraccionamientos.map((fraccionamiento, key) => 
                            <option key={key} value={fraccionamiento.id_fraccionamiento}>{fraccionamiento.nombre}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="input-form-content row full align-center">
                <div className="column label-big">
                    <p>
                        <b>Subfraccionamiento</b>
                    </p>
                </div>
                <div className="row full input-add">
                    <select
                        className="input input-modals"
                        value={formValues.id_subfraccionamiento}
                        onChange={subfraccionamientoChange.bind(this)}
                        disabled={disabled.subfraccionamiento}>
                        <option value="0">Todos los subfraccionamientos</option>
                        {subfraccionamientos.map((sub, key) => 
                            <option key={key} value={sub.id_subfraccionamiento}>{sub.nombre}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="input-form-content row full align-center">
                <div className="column label-big">
                    <p>
                        <b>Vivienda</b>
                    </p>
                </div>
                <div className="column full">
                    <div className="row full input-add">
                        <select
                            className="input input-modals"
                            value={formValues.id_vivienda}
                            onChange={(event) => setValue('id_vivienda', event.target.value)}
                            disabled={disabled.vivienda}>
                            <option value="0">Todas las viviendas</option>
                            {viviendas.map((viv, key) => 
                                <option key={key} value={viv.id_vivienda}>{viv.numero_registro}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
            <div className="input-form-content row full align-center">
                <div className="column label-big">
                    <p>
                        <b>Título</b>
                    </p>
                </div>
                <div className="column full">
                    <div className="row full input-add">
                        <input
                            className="input input-modals"
                            value={formValues.titulo}
                            onChange={(event) => setValue('titulo', event.target.value)}
                            maxLength="100"
                            required
                            disabled={disabled.titulo}>
                        </input>
                    </div>
                </div>
            </div>
            <div className="input-form-content row full align-center">
                <div className="column label-big">
                    <p>
                        <b>Mensaje</b>
                    </p>
                </div>
                <div className="column full">
                    <div className="row full input-add">
                        <textarea
                            className="input input-modals"
                            value={formValues.mensaje}
                            onChange={(event) => setValue('mensaje', event.target.value)}
                            maxLength="250"
                            rows="2"
                            required
                            disabled={disabled.vivienda}>
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consumer(NotificacionForm);
