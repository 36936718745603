
import React, { Component } from 'react';
import ReactModal from "react-modal";
import Request from "../../../core/httpClient";
const request = new Request();

class ModalEditFracc extends Component {
    constructor(props) {
        super(props);
        this.state = {

            showModalEddit: false,
            eddit: true,
            idFracc: this.props.id,
            nombreFrac: '',
            descripcion: '',
            direccion: '',
            municipio: '',
            estado: '',
            pais: '',
            contacto: '',
            telefono: '',
            email: '',
            subfraccionamientos: []


        }


    }

    render() {



        return (
            <div>
                < div className="inquilino-btns btn-container ">
                    <button type="button" onClick={this.handleEddit.bind(this)} >
                        <i className="fas fa-pen" /> Editar
                                     </button>

                    <button type="button" >
                        <i className="fas fa-times" /> Remover
                    </button>

                </div>
                {
                    <ReactModal isOpen={this.state.showModalEddit} className="modal modal-houses column" overlayClassName="overlay" >
                        <form className="flex justify-center" onSubmit={this.handledSubmit.bind(this)} >
                            <div className="container column">
                                <div className="close-modal justify-end">
                                    <button
                                        className="btn-modal-close color-dark"
                                        type="button"
                                        onClick={this.handleCloseEddit.bind(this)}>
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                                <div className=" justify-start ">
                                    <button className="color-dark" type="button" onClick={this.handleEnable.bind(this)} >
                                        <i className="fas fa-edit" />
                                        Clic para editar
                                 </button>
                                </div>
                                <div>
                                    <h3>Datos del fracccionamiento</h3>

                                </div>
                                <div className="white-space-16" />
                                <div className="column">

                                    <div className="align-center">
                                        <p><b>Nombre:</b></p>

                                        <input type="text" onChange={event => this.setState({ nombreFrac: event.target.value })} name="fullName" className="input" value={this.state.nombreFrac} disabled={this.state.eddit} placeholder="Nombre del fracccionamiento"
                                            required minLength="6" maxLength="100" />

                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Descripcion:</b>
                                        </p>
                                        <input type="text" name="description" onChange={event => this.setState({ descripcion: event.target.value })} className="input" value={this.state.descripcion} disabled={this.state.eddit} placeholder="Descripcion de fraccionamiento"
                                            required minLength="6" maxLength="150" />
                                    </div>

                                    <div className="white-space-24" />
                                    <div className="align-center">
                                        <p>
                                            <b>Dirección:</b>
                                        </p>
                                        <input type="text" onChange={event => this.setState({ direccion: event.target.value })} name="address" className="input" disabled={this.state.eddit} value={this.state.direccion} placeholder="Dirección de contacto"
                                            required minLength="6" maxLength="150" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Municipio:</b>
                                        </p>
                                        <input type="text" onChange={event => this.setState({ municipio: event.target.value })} name="municipality" className="input" disabled={this.state.eddit} value={this.state.municipio} placeholder="Municipio"
                                            required minLength="6" maxLength="80" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Estado:</b>
                                        </p>
                                        <input type="text" name="state" onChange={event => this.setState({ estado: event.target.value })} className="input" disabled={this.state.eddit} value={this.state.estado} placeholder="Estado"
                                            required minLength="6" maxLength="80" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Pais:</b>
                                        </p>
                                        <input type="text" onChange={event => this.setState({ pais: event.target.value })} name="country" className="input" disabled={this.state.eddit} value={this.state.pais} placeholder="Pais"
                                            required maxLength="20" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Contacto:</b>
                                        </p>
                                        <input type="text" name="contact" onChange={event => this.setState({ contacto: event.target.value })} className="input" disabled={this.state.eddit} value={this.state.contacto} placeholder="contacto"
                                            required />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Teléfono:</b>
                                        </p>
                                        <input type="text" name="phone" onChange={event => this.setState({ telefono: event.target.value })} className="input" disabled={this.state.eddit} value={this.state.telefono} placeholder="Teléfono"
                                            required maxLength="24" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Email:</b>
                                        </p>
                                        <input type="email" name="email" onChange={event => this.setState({ email: event.target.value })} className="input" disabled={this.state.eddit} value={this.state.email} placeholder="email"
                                            required />
                                    </div>



                                    <div className="white-space-8" />
                                </div>
                                <div className="white-space-32" />
                                {!this.state.eddit ? (
                                    <div className="btn-container">
                                        <button type="submit" className="btn btn-primary color-white">
                                            <i className="fas fa-sync-alt"/>
                                            &nbsp; ACTUALIZAR
                                 </button>
                                        <button type="button" className="btn btn-secondary color-white" >
                                            <i className="fas fa-times" />
                                            &nbsp; CANCELAR
                                   </button>
                                    </div>
                                ) : null}

                                <div className="white-space-16" />
                                <div>
                                    <h3>SubFraccionamientos asociados</h3>

                                </div>
                                <div className="">
                                    {this.state.subfraccionamientos.map((fraccionamiento, key) => (
                                        <p className="" key={key}>
                                            {key + 1}- {fraccionamiento.nombre}- DIR:{fraccionamiento.direccion}-{"       TEL: "}{fraccionamiento.telefono}
                                        </p>
                                    ))}


                                </div>


                                <div className="white-space-24" />

                            </div>
                        </form>
                    </ReactModal>

                }
            </div>
        );
    }

    // traer datos fraccionamiento
    async getFraccionamiento() {
        
        this.getSubFraccionamiento();

        let data = { IdFraccionamiento: this.state.idFracc }
        const response = await request.post("/admin/administracion/get/fraccionamientos", data);

        if (response && !response.error) {
            if (response.fraccionamiento && !response.empty) {
                this.setState({
                    nombreFrac: response.fraccionamiento[0].nombre,
                    descripcion: response.fraccionamiento[0].descripcion,
                    direccion: response.fraccionamiento[0].direccion,
                    municipio: response.fraccionamiento[0].municipio,
                    estado: response.fraccionamiento[0].estado,
                    pais: response.fraccionamiento[0].pais,
                    contacto: response.fraccionamiento[0].contacto,
                    telefono: response.fraccionamiento[0].telefono,
                    email: response.fraccionamiento[0].correo
                });


            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }

    }
    // traer subfraccionamiento
    async getSubFraccionamiento() {

        let data = { Idsub: this.state.idFracc }

        const response = await request.post("/admin/administracion/get/subfraccionamiento", data);


        if (response && !response.error) {
            if (response.subfraccionamiento && !response.empty) {
                this.setState({
                    subfraccionamientos: response.subfraccionamiento
                });


            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }

    }
    // GUARDA FORMULARIO
    async handledSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;

        let data = {

            id_fraccionamiento: this.state.idFracc,
            Nombre: form.fullName.value,
            Descripcion: form.description.value,
            Direccion: form.address.value,
            Municipio: form.municipality.value,
            Estado: form.state.value,
            Pais: form.country.value,
            Contacto: form.contact.value,
            Telefono: form.phone.value,
            Email: form.email.value

        };
        this.setState({
            showModalEddit: false
        })
        this.props.eddit(data)

    }
    handleOpen() {
        this.setState({ showModal: true });

    }
    handleClose() {
        this.setState({ showModal: false })
    }
    handleCloseEddit() {
        this.setState({ showModalEddit: false })
    }
    handleEddit() {
        this.setState({ showModalEddit: true })
        this.getFraccionamiento()


    }
    handleEnable() {
        this.setState({
            eddit: !this.state.eddit
        })
    }

}


export default ModalEditFracc;


