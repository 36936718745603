import React, { Component } from 'react';
import ReactModal from "react-modal";
import Request from "../../../core/httpClient";
const request = new Request();
class MenusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModalMenus: false,
            eddit: true,
            menus: [],
            showCatalogos: true,
            showAdministracion: false,
            showOperacion: false,
            idFraccionamiento: this.props.id,
            listaMenus: [],
            disable: true
        }
    }

    render() {
        return (
            <div>
                < div className="inquilino-btns btn-container">
                    <button type="button" onClick={this.handleOpen.bind(this)}>
                        <i className="fas fa-boxes" /> Modulos
                </button>
                </div>
                {
                    <ReactModal isOpen={this.state.showModalMenus} className="modal modal-full modal-owners modal-scroll  column" overlayClassName="overlay">
                        <form className="flex justify-center" onSubmit={this.handleSubmit.bind(this)}>
                            <div className="full column container">
                                <div className="close-modal justify-end header-modals">
                                    <div>
                                        <h3 className="">Permisos</h3>
                                    </div>
                                    <button className="btn-modal-close color-dark" onClick={this.handleClose.bind(this)}>
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                                <div className="white-space-16" />
                                <div className="row tab">
                                    <div className="column container align-center" style={{ backgroundColor: '#f1f1f1' }}>
                                        <button type='button' className="tablinks " style={{ margin: '15px', hover: '#ddd' }} onClick={this.showCatalos.bind(this)}>Catalogos</button>
                                    </div>
                                    <div className="column container align-center" style={{ backgroundColor: '#f1f1f1' }}>
                                        <button type='button' className="tablinks " style={{ margin: '15px', }} onClick={this.showAdministracion.bind(this)}>Administración</button>
                                    </div>
                                    <div className="column container align-center" style={{ backgroundColor: '#f1f1f1' }}>
                                        <button type='button' className="tablinks " style={{ margin: '15px', }} onClick={this.showOperacion.bind(this)}>Operación</button>
                                    </div>
                                </div>


                                <div className="row  justify-center">
                                    <div className="column container align-center" >
                                        <h4 > Menú</h4>
                                    </div>

                                    <div className="column align-center">
                                        <h4>Contratado</h4>
                                    </div>

                                </div>

                                {this.state.showCatalogos ? (
                                    <div>
                                        {console.log(this.state.menus)}
                                        {this.state.menus.map((menu, key) => (


                                            <div className="row justify-center" key={key} style={{ paddingLeft: '25px' }}>

                                                <div className="column container  align-left" >
                                                    {menu.pantalla === -1 ? (
                                                        <div>
                                                            {menu.parent === 0 ? (
                                                                <h3 className="" name='menuName' >
                                                                    {menu.nombre}

                                                                </h3>
                                                            ) :
                                                                <h5 className="" name='menuName' >
                                                                    {menu.nombre}

                                                                </h5>}

                                                        </div>

                                                    ) : null}


                                                </div>

                                                {menu.pantalla === -1 ? (
                                                    <div className="row justify-center" key={key} style={{ paddingLeft: '25px', paddingTop: '55 px' }}>
                                                        <div className="column align-center" >
                                                            <input type="checkbox" name="contratado" id="contratado" onChange={this.setOption.bind(this, 'contratado', menu, key)}
                                                                checked={menu.contratado ? (true) : (false)} />
                                                        </div>


                                                    </div>
                                                ) : null}

                                            </div>

                                        ))}
                                    </div>

                                ) : null}
                                {this.state.showAdministracion ? (
                                    <div>

                                        {this.state.menus.map((menu, key) => (


                                            <div className="row justify-center" key={key} style={{ paddingLeft: '25px' }}>


                                                <div className="column container  align-left" >
                                                    {menu.pantalla === -2 ? (
                                                        <div>
                                                            {menu.parent === 0 ? (
                                                                <h3 className="" name='menuName' >
                                                                    {menu.nombre}

                                                                </h3>
                                                            ) :
                                                                <h5 className="" name='menuName' >
                                                                    {menu.nombre}

                                                                </h5>}

                                                        </div>

                                                    ) : null}


                                                </div>
                                                {menu.pantalla === -2 ? (
                                                    <div className="row justify-center" key={key} style={{ paddingLeft: '25px', paddingTop: '55 px' }}>
                                                        <div className="column align-center" >
                                                            <input type="checkbox" name="lectura" id="lectura" onChange={this.setOption.bind(this, 'lectura', menu, key)}
                                                                checked={menu.lectura ? (true) : (false)} />
                                                        </div>


                                                    </div>
                                                ) : null}

                                            </div>

                                        ))}
                                    </div>

                                ) : null}
                                {this.state.showOperacion ? (
                                    <div>

                                        {this.state.menus.map((menu, key) => (


                                            <div className="row justify-center" key={key} style={{ paddingLeft: '25px' }}>


                                                <div className="column container  align-left" >
                                                    {menu.pantalla === -3 ? (
                                                        <div>
                                                            {menu.parent === 0 ? (
                                                                <h3 className="" name='menuName' >
                                                                    {menu.nombre}

                                                                </h3>
                                                            ) :
                                                                <h5 className="" name='menuName' >
                                                                    {menu.nombre}

                                                                </h5>}

                                                        </div>

                                                    ) : null}


                                                </div>
                                                {menu.pantalla === -3 ? (
                                                    <div className="row justify-center" key={key} style={{ paddingLeft: '25px', paddingTop: '55 px' }}>
                                                        <div className="column align-center" >
                                                            <input type="checkbox" name="lectura" id="lectura" onChange={this.setOption.bind(this, 'lectura', menu, key)}
                                                                checked={menu.lectura ? (true) : (false)} />
                                                        </div>


                                                    </div>
                                                ) : null}

                                            </div>

                                        ))}
                                    </div>

                                ) : null}

                                <div className="btn-container" style={{ paddingLeft: '25px', paddingTop: '25 px' }}>
                                    <button type="submit" disabled={this.state.disable} className="btn btn-primary color-white" >
                                        <i className="fas fa-check" />
                                        &nbsp; GUARDAR
                                         </button>
                                    <button type="button" className="btn btn-secondary color-white" onClick={this.handleClose.bind(this)}>
                                        <i className="fas fa-times" />
                                        &nbsp; CANCELAR
                                     </button>
                                </div>
                                <div className="white-space-16"></div>


                            </div>

                        </form>
                    </ReactModal>
                }

            </div>

        );
    }
    handleOpen() {
        this.setState({ showModalMenus: true });
        if (this.state.menus.length > 0) {

            return

        } else {
            this.getModulos()
        }


    }
    handleClose() {
        this.setState({ showModalMenus: false, disable: true })
    }
    handleCloseEddit() {
        this.setState({ showModalEddit: false })
    }
    handleEddit() {
        this.setState({ showModalEddit: true })
        this.getFraccionamiento()


    }
    async handleSubmit(event) {
        event.preventDefault();
        let menu = JSON.stringify(this.state.listaMenus)
        let menu2 = menu.slice(1, -1)
        ////console.log('menu2: ', menu2);
        //Datos al API
        const data = {


            idFraccionamiento: this.state.idFraccionamiento,
            menus: menu2

        }
        ////console.log(data);


        const response = await request.post('/profiles/permissions', data);
        ////console.log('response: ', response);
        // //console.log('response: ', response);


        if (response && !response.error) {
            if (response.updated && !response.empty) {

                this.setState({
                    showModalMenus: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });


        }

    }
    showAdministracion() {
        this.setState({ showAdministracion: true, showCatalogos: false })
    }
    showCatalos() {
        this.setState({ showCatalogos: true, showAdministracion: false })
    }
    showOperacion() {
        this.setState({ showOperacion: true, showCatalogos: false })
    }





    handleEnable() {
        this.setState({
            eddit: !this.state.eddit
        })
    }
    // traer todos los modulos

    async getModulos() {


        let data = { fraccionamiento: this.state.idFraccionamiento }
        const response = await request.post("/admin/administracion/get/modulos", data);
        ////console.log('response: ', response);

        if (response && !response.error) {
            if (response.modulos && !response.empty) {
                this.setState({
                    menus: response.modulos
                });


            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }



    }
    setOption(option, menu, key, event) {

        const perfil = this.state.menus

        if (option === 'contratado') {
            perfil[key].contratado = event.target.checked;
        }
        let valores = []

        this.state.menus.forEach(valor => {
            if (valor.contratado === null || valor.contratado === false || valor.contratado === 0) {
                valores.push(`${valor.id_menu}:${0}`);
            } else {

                valores.push(`${valor.id_menu}:${1}`);
            }

        });



        this.setState({ listaMenus: valores.toString(), disable: false })

        // this.setState({ menus: JSON.stringify(perfil) })

        // menu[option] = event.target.checked;
        // const elemento = this.state.menus.find(men => men.id_menu.toString() === menu.id_menu.toString());
        // Object.assign(elemento, menu);
    }
}

export default MenusModal;