import React, { Component } from 'react';
import ReactModal from "react-modal";
import { withRouter } from 'react-router-dom';
import ModalUserEddit from "./modalUserEddit";

import Request from "../../../core/httpClient";
const request = new Request();

class UserTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModalUser: false,
            id: this.props.location.state.id,
            eddit: true,
            usuarios: []
        }

    }
    edditUser = data => {


        this.guardar(data)

    };

    deleteUser = data => {
        this.delete(data);

    }

    render() {
        return (

            <div className="card-table card-owners column" id="card-owners">
                <h4>Usuarios de Administración</h4>
                <div className=" justify-end">
                    <button className="btn-modal-close color-dark" type="button" onClick={this.handleOpen.bind(this)}  >
                        <i className="fas fa-plus" />
                        Agregar Usuario
                    </button>
                </div>
                <div className="white-space-8" />
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-center">
                                <th className="name">Nombre</th>
                                <th className="name">Usuario</th>
                                <th className="text-right" >Acciones</th>


                            </tr>
                        </thead>

                        <tbody>
                            {this.state.usuarios.map((usuario, key) => (
                                <tr key={key}>

                                    <td className="text-center">
                                        {usuario.nombre}
                                    </td>
                                    <td className="text-center">
                                        {usuario.usuario}
                                    </td>
                                    <td className="text-right">
                                        <ModalUserEddit id={usuario.id_usuario} edditUser={this.edditUser} deleteUser={this.deleteUser}></ModalUserEddit></td>


                                </tr>
                            ))}




                        </tbody>
                    </table>

                </div>
                {/* modal agregar usuario */}

                {
                    <ReactModal isOpen={this.state.showModalUser} className="modal modal-houses column" overlayClassName="overlay" >
                        <form className="flex justify-center" onSubmit={this.handledSubmit.bind(this)}>
                            <div className="container column">

                                <div className="close-modal justify-end">
                                    <button className="btn-modal-close color-dark" onClick={this.handleClose.bind(this)}>
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                                <div>
                                    <h3>Registrar Usuario</h3>

                                </div>
                                <div className="white-space-16" />
                                <div className="column">
                                    <div className="align-center">
                                        <p><b>Nombre:</b></p>

                                        <input type="text" name="name" className="input" placeholder="Nombre"
                                            required minLength="6" maxLength="100" />

                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Usuario:</b>
                                        </p>
                                        <input type="text" name="usuario" className="input" placeholder="Usuario"
                                            required minLength="6" maxLength="150" />
                                    </div>

                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>password:</b>
                                        </p>
                                        <input type="password" name="password" className="input" placeholder="Password"
                                            required minLength="6" maxLength="150" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Perfil:</b>
                                        </p>
                                        <select name="perfil" id="" className="input" disabled>
                                            <option value="2">administrador</option>
                                        </select>
                                    </div>



                                </div>
                                <div className="white-space-32" />
                                <div className="btn-container">
                                    <button type="submit" className="btn btn-primary color-white">
                                        <i className="fas fa-check" />
                                        &nbsp; Guardar
                                  </button>
                                    <button type="button" className="btn btn-secondary color-white" onClick={this.handleClose.bind(this)}>
                                        <i className="fas fa-times" />
                                        &nbsp; CANCELAR
                                     </button>
                                </div>
                            </div>
                        </form>
                    </ReactModal>
                }


            </div>
        );
    }
    componentDidMount() {

        this.getUsuarios();
    }

    // // TRAER TODOS LOS USUARIOS de la administracion
    async getUsuarios() {

        let data = { IdAdministracion: this.state.id };

        const response = await request.post("/admin/administracion/get/usuarios", data);



        if (response && !response.error) {
            if (response.usuarios && !response.empty) {
                this.setState({

                    usuarios: response.usuarios

                });

            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });
        }
    }
    // GUARDA FORMULARIO
    async handledSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;

        let data = {


            Nombre: form.name.value,
            Usuario: form.usuario.value,
            Password: form.password.value,
            Perfil: parseInt(form.perfil.value),
            IdAdministracion: this.state.id
        };

        const response = await request.post("/admin/save/user", data);
       console.log(response);



        if (response && !response.error) {
            if (response.created && !response.empty) {
                this.setState({ showModalUser: false });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }
        this.getUsuarios()

    }
    handleOpen() {
        this.setState({ showModalUser: true });

    }
    handleClose() {
        this.setState({ showModalUser: false })
    }

    handleEnable() {
        this.setState({
            eddit: !this.state.eddit
        })
    }
    async guardar(data) {
        const response = await request.post("/admin/administracion/eddit/user", data);


        if (response && !response.error) {
            if (response.eddited && !response.empty) {
                this.setState({

                    eddit: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }


        this.getUsuarios()
    }
    async delete(data) {

        const response = await request.post("/admin/administracion/delet/user", data);
        ////console.log('response: ', response);


        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.setState({

                    eddit: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }


        this.getUsuarios()
    }
}

export default withRouter(UserTable);