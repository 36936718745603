import React, { Component } from 'react';
import ReactModal from "react-modal";
import { withRouter } from 'react-router-dom';
import ModalEditFracc from "./fraccionamientoModal";
import MenusModal from './menusModal';
import modalDelete from '../../modals/modalDelete';
import Request from "../../../core/httpClient";
const request = new Request();
class FraccionamientosTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            id: this.props.location.state.id,
            eddit: true,
            Fraccionamientos: [],
            idFraccionamiento: 0
        }
    }
    edditFracc = data => {this.guardar(data)};
    deleteFracc = data => {this.delete(data);

    }

    render() {
        return (

            <div className="card-table card-owners column" id="card-owners">
                <h4>Fraccionamientos Asociados </h4>
                <div className=" justify-end">
                    <button className="btn-modal-close color-dark" type="button" onClick={this.handleOpen.bind(this)}  >
                        <i className="fas fa-plus" />
                        Agregar Fraccionamiento
                    </button>
                </div>
                <div className="white-space-8" />
                <div className="table-responsive">
                    <table>
                        <thead>
                            <tr className="text-left">
                                <th className="name">Nombre</th>
                                <th className="vivienda ">Estado</th>
                                <th className="vivienda ">Contacto</th>
                                <th className="">Teléfono</th>
                                <th className="">Email</th>
                                <th className="date text-center" >Acciones</th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.state.Fraccionamientos.map((fraccionaiento, key) => (
                                <tr key={key}>

                                    <td>{fraccionaiento.nombre}</td>
                                    <td>{fraccionaiento.estado}</td>
                                    <td>{fraccionaiento.contacto}</td>
                                    <td>{fraccionaiento.telefono}</td>
                                    <td>{fraccionaiento.correo}</td>
                                    <td>
                                        <div className="inquilino-btns btn-container align-center">
                                            <ModalEditFracc id={fraccionaiento.id_fraccionamiento} eddit={this.edditFracc}></ModalEditFracc>
                                            <modalDelete  deleteFracc={this.deleteFracc}></modalDelete>
                                            <MenusModal id={fraccionaiento.id_fraccionamiento}></MenusModal>
                                        </div>
                                    </td>
                                </tr>
                            ))}


                        </tbody>
                    </table>

                </div>
                {/* modal agregar fraccionaiento */}

                {
                    <ReactModal isOpen={this.state.showModal} className="modal modal-houses column" overlayClassName="overlay" >
                        <form className="flex justify-center" onSubmit={this.handledSubmit.bind(this)}>
                            <div className="container column">

                                <div className="close-modal justify-end">
                                    <button className="btn-modal-close color-dark" onClick={this.handleClose.bind(this)}>
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                                <div>
                                    <h3>Datos del Fracccionamiento</h3>

                                </div>
                                <div className="white-space-16" />
                                <div className="column">
                                    <div className="align-center">
                                        <p><b>Nombre:</b></p>

                                        <input type="text" name="nameFrac" className="input" placeholder="Nombre del fracccionamiento"
                                            required minLength="6" maxLength="100" />

                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Descripción:</b>
                                        </p>
                                        <input type="text" name="description" className="input" placeholder="Descripcion de fraccionamiento"
                                            required minLength="6" maxLength="150" />
                                    </div>

                                    <div className="white-space-24" />
                                    <div className="align-center">
                                        <p>
                                            <b>Dirección:</b>
                                        </p>
                                        <input type="text" name="address" className="input" placeholder="Dirección de contacto"
                                            required minLength="6" maxLength="150" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Municipio:</b>
                                        </p>
                                        <input type="text" name="municipality" className="input" placeholder="Municipio"
                                            required minLength="6" maxLength="80" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Estado:</b>
                                        </p>
                                        <input type="text" name="state" className="input" placeholder="Estado"
                                            required minLength="6" maxLength="80" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>País:</b>
                                        </p>
                                        <input type="text" name="country" className="input" placeholder="Pais"
                                            required maxLength="20" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Contacto:</b>
                                        </p>
                                        <input type="text" name="contact" className="input" placeholder="contacto"
                                            required />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Teléfono:</b>
                                        </p>
                                        <input type="text" name="phone" className="input" placeholder="Teléfono"
                                            required maxLength="24" />
                                    </div>
                                    <div className="white-space-8" />
                                    <div className="align-center">
                                        <p>
                                            <b>Email:</b>
                                        </p>
                                        <input type="email" name="email" className="input" placeholder="email"
                                            required />
                                    </div>
                                    <div className="white-space-8" />
                                </div>
                                <div className="white-space-32" />
                                <div className="btn-container">
                                    <button type="submit" className="btn btn-primary color-white">
                                        <i className="fas fa-check" />
                                        &nbsp; GUARDAR
                                  </button>
                                    <button type="button" className="btn btn-secondary color-white" >
                                        <i className="fas fa-times" />
                                        &nbsp; CANCELAR
                                     </button>
                                </div>
                            </div>
                        </form>
                    </ReactModal>
                }


            </div>
        );
    }
    componentDidMount() {
        let id = this.state.id
        this.getFraccionamientos(id)
    }
    async  getFraccionamientos(id) {

        let data = { IdAdministracion: id }



        const response = await request.post("/admin/administracion/get/fraccionamientos", data);


        if (response && !response.error) {
            if (response.fraccionamientos && !response.empty) {
                this.setState({
                    Fraccionamientos: response.fraccionamientos
                });


            } else {
                this.setState({
                    empty: true,
                    message: response.message
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message
            });

        }





    }
    // GUARDA FORMULARIO
    async handledSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const form = event.target;

        let data = {

            Nombre: form.nameFrac.value,
            Descripcion: form.description.value,
            Direccion: form.address.value,
            Municipio: form.municipality.value,
            Estado: form.state.value,
            Pais: form.country.value,
            Contacto: form.contact.value,
            Telefono: form.phone.value,
            email: form.email.value,
            IdAdministracion: this.state.id
        };
        const response = await request.post("/admin/administracion/fraccionamiento/create", data);

        this.setState({ showModalAddAdmin: false });
        if (response && !response.error) {
            if (response.created && !response.empty) {
                this.setState({

                    showModal: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }
        this.getFraccionamientos(this.state.id)

    }
    handleOpen() {
        this.setState({ showModal: true });

    }
    handleClose() {
        this.setState({ showModal: false })
    }

    handleEnable() {
        this.setState({
            eddit: !this.state.eddit
        })
    }
    async guardar(data) {
        const response = await request.post("/admin/administracion/eddit/fraccionamiento", data);

        if (response && !response.error) {
            if (response.eddited && !response.empty) {
                this.setState({

                    eddit: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }

        let id = this.state.id
        this.getFraccionamientos(id)
    }
    openModalDelete(inq) {
		this.setState({id_contacto: inq.id_contacto});
		let modal = document.getElementById("deleteModal");
		if(modal) {
			modal.classList.remove('hide-modal');
			modal.classList.add('show-modal');
		}
	}
    async delete(data) {

        const response = await request.post("/admin/administracion/delet/fraccionamiento", data);
        ////console.log('response: ', response);


        if (response && !response.error) {
            if (response.deleted && !response.empty) {
                this.setState({

                    eddit: false
                });
            } else {
                this.setState({
                    empty: true,
                    message: response.message,
                    loading: false
                });
            }
        } else {
            this.setState({
                error: true,
                message: response.message,
                loading: false
            });
        }


        this.getFraccionamientos()
    }
}

export default withRouter(FraccionamientosTable);